// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.App-header {
  .wrapper {
    padding-left: 0 !important;
  }
}

.searchbar {
  width: 18rem;
  padding: 0.3rem;
  border: none;
  border-radius: 100rem;
  padding-top: 0.5rem;
  margin: 0.5rem;
  box-shadow: none;
  outline: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
}
